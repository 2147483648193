





















































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api'
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper'
import 'hooper/dist/hooper.css'
import JapanPostalCode from 'japan-postal-code'
import SearchInput from '~/components/search/Input.vue'
import { getDateString } from '~/libs/dayjs'
import ListEventsTop from '~/components/events/ListEventsTop.vue'
import NoteItem from '~/components/pickups/NoteItem.vue'
import AdFrame from '~/components/AdFrame.vue'
import ListNoteTop from '~/components/ListNoteTop.vue'
import YoutubeLive from '~/components/YoutubeLive.vue'
import CampaignsItem from '~/components/campaigns/item.vue'
import Meta from '~/assets/mixins/meta'
import LogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
import IconCalendarSvg from '~/assets/images/svg/icon_calendar.svg?inline'

import themeTrend from '~/assets/images/svg/theme_trend.svg?inline'
import themeCreativity from '~/assets/images/svg/theme_creativity.svg?inline'
import themeBusiness from '~/assets/images/svg/theme_business.svg?inline'
import themeCareer from '~/assets/images/svg/theme_career.svg?inline'
import themeSkill from '~/assets/images/svg/theme_skill.svg?inline'
import { convertImage, convertAssetImage } from '~/libs/imagepix'
import { campaigns } from '~/libs/campaigns'
import useCookies from '~/composables/useCookies'
import { date } from '~/libs/validation-custom-rules'
// @ts-ignore
import IconPaySvg from '~/assets/images/svg/icon_pay.svg?inline'

export default defineComponent({
  components: {
    SearchInput,
    AdFrame,
    ListNoteTop,
    ListEventsTop,
    NoteItem,
    YoutubeLive,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    LogoVookSvg,
    IconCalendarSvg,
    CampaignsItem,
    themeTrend,
    themeCreativity,
    themeBusiness,
    themeCareer,
    themeSkill,
    IconPaySvg
  },
  mixins: [Meta],
  watchQuery: true,
  setup() {
    const { $axios, $auth } = useContext()
    const $cookies = useCookies()
    const $router = useRouter()

    const hooper = ref()
    const recommendations = ref<Note[]>([])
    const pickups = ref<Note[]>([])
    const notes = ref<Note[]>([])
    const noteSummaries = ref<Note[]>([])
    const careers = ref<careers.JobOffer[]>([])
    const movies = ref<Note[]>([])
    const trendNotes = ref<Note[]>([])
    const creativityNotes = ref<Note[]>([])
    const businessNotes = ref<Note[]>([])
    const careerNotes = ref<Note[]>([])
    const skillNotes = ref<Note[]>([])

    // 特定のcookieがあった場合はリダイレクト
    if ($cookies.get('archivePath')) {
      const archivePath = $cookies.get('archivePath')
      $cookies.remove('archivePath')
      $router.push(archivePath)
    }
    if ($cookies.get('eventPath')) {
      const eventPath = $cookies.get('eventPath')
      $cookies.remove('eventPath')
      $router.push(eventPath)
    }

    const fetchRecommendations = async () => {
      try {
        const responseData = await $axios.$get('/top_page/recommendations')
        recommendations.value = responseData.slice(0, 6)
      } catch (error) {
        alert(error)
      }
    }

    const fetchPickups = async () => {
      try {
        const responseData = await $axios.$get('/top_pickups')
        pickups.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchNotes = async () => {
      try {
        const params = { per: 10 }
        const responseData = await $axios.$get('/v2/notes', { params })
        notes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchNoteSummaries = async () => {
      try {
        const responseData = await $axios.$get('/note_summaries')
        noteSummaries.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCareers = async () => {
      try {
        const responseData = await $axios.$get(
          '/careers/job_offers?q[s]=recruiting_begin_at+desc&per=6'
        )
        careers.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchMovies = async () => {
      try {
        const params = { per: 10, type: 'movie' }
        const responseData = await $axios.$get('/v2/notes', { params })
        movies.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchTrendNotes = async () => {
      try {
        const params = { per: 4, theme: 1 }
        const responseData = await $axios.$get('/v2/notes', { params })
        trendNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCreativityNotes = async () => {
      try {
        const params = { per: 4, theme: 2 }
        const responseData = await $axios.$get('/v2/notes', { params })
        creativityNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchBusinessNotes = async () => {
      try {
        const params = { per: 4, theme: 3 }
        const responseData = await $axios.$get('/v2/notes', { params })
        businessNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCareerNotes = async () => {
      try {
        const params = { per: 4, theme: 4 }
        const responseData = await $axios.$get('/v2/notes', { params })
        careerNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchSkillNotes = async () => {
      try {
        const params = { per: 4, theme: 5 }
        const responseData = await $axios.$get('/v2/notes', { params })
        skillNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const clickSlide = (event: any) => {
      if (hooper.value.isSlding) {
        event.preventDefault()
      }
    }

    const premium = (note: Note) => {
      if (note.only_premium) {
        return true
      }

      if (note.movie?.permission === 'paid') {
        return true
      }
      return false
    }

    const campaignsTop = computed(() => {
      // SPでは4件表示。PCでは、スタイルで4件目を非
      return campaigns.slice(0, 4)
    })

    onMounted(async () => {
      await Promise.all([
        fetchRecommendations(),
        fetchPickups(),
        fetchNotes(),
        fetchMovies(),
        fetchNoteSummaries(),
        fetchCareers(),
        fetchTrendNotes(),
        fetchCreativityNotes(),
        fetchBusinessNotes(),
        fetchCareerNotes(),
        fetchSkillNotes()
      ])
    })

    return {
      meta: {},
      bodyAttrs: { class: 'header-fixed' },
      recommendations,
      pickups,
      notes,
      movies,
      noteSummaries,
      careers,
      campaignsTop,
      trendNotes,
      creativityNotes,
      businessNotes,
      careerNotes,
      skillNotes,
      clickSlide,
      convertImage,
      convertAssetImage,
      getDateString,
      premium,
      date,
      hooperSettings: {
        centerMode: true,
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        playSpeed: 4500,
        itemsToShow: 1,
        itemsToSlide: 1,
        breakpoints: {
          600: {
            itemsToShow: 1.3
          }
        }
      }
    }
  }
})
