













import {
  defineComponent,
  useContext,
  ref,
  onMounted
} from '@nuxtjs/composition-api'
import EventsList from '~/components/events/EventsList.vue'

const EVENT_PER = 4

export default defineComponent({
  components: {
    EventsList
  },
  props: {
    themeId: {
      type: Number,
      default: undefined
    }
  },
  setup(props) {
    const { $axios } = useContext()

    const events = ref<Event[]>([])

    onMounted(() => {
      const params = { per: EVENT_PER, theme_id: props.themeId }
      $axios
        .$get<Event[]>('/v2/events', { params })
        .then((data) => {
          events.value = data
        })
    })

    return {
      events
    }
  }
})
